import { Store } from '@ngrx/store';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { ShowConditionsOfUseComponent } from 'src/app/components/shared/show-conditions-of-use/show-conditions-of-use.component';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { selectProfileUserRights$ } from '../../../store/selectors/profile.selectors';
import { UserRightService } from 'src/app/services/user-right.service';
import { filter, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketService } from 'src/app/services/websocket.service';
import { UntypedFormGroup } from '@angular/forms';
import { Scope } from 'src/app/models/profile';
import { ProfileCardComponent } from '../profile-card/profile-card.component';
import { WebsocketInfoComponent } from './websocket-info.component';
import { ConfigurationStorageKeys, LocalStorageService } from 'src/app/services/local-storage.service';
import { detailExpand, rotate } from '../animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileBottomSheetComponent } from './mobile-bottom-sheet/mobile-bottom-sheet.component';
import { HelpListService } from './help-list.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TutorialService } from '../tutorial/tutorial.service';
import { TroubleshootingComponent } from '../../troubleshooting/troubleshooting.component';

@Component({
    selector: 'app-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [detailExpand, rotate(360)],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    public env;
    public decodedToken: any;
    public isMapView = false;
    public pageLoaded = false;
    public filterDisplayState = false;
    public mobile: boolean;
    public mobileMenuIsOpened = false;
    public isOversize: boolean;
    public isAct: boolean; // true if the user has the act scope
    public isManager: boolean;
    public isAdmin: boolean;
    public isGroupAdmin = false;
    public isUserInfoDisplayed = false;
    public isShowWebSocketInfo = false;
    public isShowWebSocketRefresh = false;
    public webSocketForm: UntypedFormGroup;
    public webSocketRealTime: boolean;
    public appNameIcon: IconProp;
    public userIcon: IconProp;
    private _firstLogin = false;
    private _subscription: Subscription = new Subscription();

    constructor(
        public dialog: MatDialog,
        public auth: AuthService,
        public router: Router,
        public helpListService: HelpListService,
        private cd: ChangeDetectorRef,
        private helper: JwtHelperService,
        private store: Store,
        private _userRightService: UserRightService,
        public translate: TranslateService,
        private _webSocketService: WebSocketService,
        private _localStorageService: LocalStorageService,
        private readonly _breakpointObserver: BreakpointObserver,
        private readonly _bottomSheet: MatBottomSheet,
        private readonly _tutorialService: TutorialService,
    ) {
        this.router.events.subscribe((route) => {
            if (route['url']) {
                this.isMapView = route['url'] === '/map/view-map';
            }
        });
        this._subscription.add(
            this._breakpointObserver.observe([
                Breakpoints.Handset,
            ]).subscribe(
                ({ matches }) => {
                    this.mobile = matches;
                    if (!matches) {
                        this.mobileMenuIsOpened = false;
                    }
                }
            )
        );
    }

    ngOnInit() {
        this.env = environment;
        if (this._firstLogin === true) {
            this.openConditionsOfUse();
        }
        this._subscription.add(this.auth.tokenValid.pipe(
            filter(v => !!v),
        ).subscribe(() => {
            this.decodedToken = this.helper.decodeToken(this.auth.getJwtToken());
            this.auth.userNg = this.decodedToken.preferred_username;
        }));

        this._subscription.add(this.store.select(selectProfileUserRights$).subscribe((userRight) => {
            this.isOversize = userRight.scope.includes(Scope.OVERSIZE);
            this.isAct = userRight.scope.includes(Scope.ACT);
            this.isAdmin = userRight.isAdmin;
            this.isGroupAdmin = userRight.isGroupAdmin;
        }));

        this._subscription.add(this._userRightService.getIsManager$().pipe(take(1)).subscribe((isManager) => {
            this.isManager = isManager;
        }));

        this._subscription.add(this._webSocketService.webSocketRealTime$.subscribe(webSocketRealTime => {
            this.webSocketRealTime = webSocketRealTime;
            this.cd.markForCheck();
        }));

        if (window.screen.width <= 768) { // 768px portrait
            this.mobile = true;
        }

        // Fetch values from local storage
        this._localStorageService.fetchLocalStorageValues$().subscribe();
        this._localStorageService.fetchLocalStorageValueByKey$(ConfigurationStorageKeys.troubleshootTutorial).pipe(
            take(1),
        ).subscribe(
            ({ value }) => {
                if (!value) {
                    setTimeout(() => this.showTroubleshootingTool())
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.userIcon = 'user';
        if (new Date().getMonth() === 9 && new Date().getDate() === 31) {
            this.appNameIcon = 'spider';
            this.userIcon = 'ghost';
        }
        if (new Date().getMonth() === 11 && new Date().getDate() > 20) {
            this.appNameIcon = 'gift';
            this.userIcon = 'snowman';
        }
    }

    /**
     * Open a popup containing the application conditions of use
     */
    public openConditionsOfUse() {
        this.dialog.open(ShowConditionsOfUseComponent, {
            disableClose: true,
            closeOnNavigation: false,
            autoFocus: false,
            data: { validation: true },
        });
    }


    /**
     * set boolean false to close popup
     */
    public closePopups(): void {
        this.isShowWebSocketRefresh = false;
        this.isShowWebSocketInfo = false;
    }

    public showWebSocketInfo() {
        this.dialog.open(WebsocketInfoComponent, {
            minWidth: '250px',
            width: '25vw',
        });
    }

    public openUserInfo() {
        this.dialog.open(ProfileCardComponent);
    }

    public openMenuMobile() {
        this._bottomSheet.open<MobileBottomSheetComponent, typeof this.helpListService.HELP_LIST>(
            MobileBottomSheetComponent,
            {
                data: this.helpListService.HELP_LIST,
            }
        );
    }

    public async openLanguageSelection(event: MouseEvent|KeyboardEvent) {
        const title = this.translate.instant('SELECT_LANGUAGE');
        const icon = 'translate';
        const userProfileButtonTarget = document.querySelector<HTMLButtonElement>('button#user-info');
        this._tutorialService.launch([
            {
                title,
                icon,
                text: this.translate.instant('LANGUAGE_SELECTION_MOVED'),
                target: event.target as Element,
            },
            {
                title,
                icon,
                text: this.translate.instant('LANG_CLICK_ON_ICON'),
                target: userProfileButtonTarget,
                actionOn: userProfileButtonTarget,
                actionHighlightClass: 'highlight-button-on-tutorial-step',
            }
        ]);
        const closeTuto = () => {
            this._tutorialService.finish();
            userProfileButtonTarget.removeEventListener('click', closeTuto);
        };
        userProfileButtonTarget.addEventListener('click', closeTuto);
    }


    private showTroubleshootingTool() {
        const title = this.translate.instant('TROUBLESHOOTING');
        const icon = 'troubleshoot';
        const troubleshootButtonTarget = document.querySelector<HTMLButtonElement>('button#troubleshoot-button');
        const tutorial = this._tutorialService.launch([
            {
                title,
                icon,
                text: this.translate.instant('TROUBLESHOOTING_NEW_FEATURE'),
                target: troubleshootButtonTarget,
                actionOn: troubleshootButtonTarget,
                actionHighlightClass: 'highlight-button-on-tutorial-step',
            },
        ]);
        tutorial.afterClosed$.subscribe(
            () => this._localStorageService.store$(ConfigurationStorageKeys.troubleshootTutorial, "1")
        );
        troubleshootButtonTarget.addEventListener('click', () => this._tutorialService.finish());
    }

    public troubleshoot() {
        this.dialog.open(TroubleshootingComponent, {
            minWidth: '250px',
            minHeight: '50vh',
            maxHeight: '80vh'
        });
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
